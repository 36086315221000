.RootAboutUs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 32px;
}

.ListView {
    margin: 16px;
    width: 300px;
    max-width: 100%;
}

.FAQ {
    width: 300px;
    text-align: left;
}

.Disclaimer {
    width: 300px;
    margin-top: 16px;
}