a:link {
    color: grey;
}

a:visited {
    color: grey;
}

.RootSearchImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 18px 32px 32px 32px;
}

h2 {
    margin: 8px;
}

.AppDescription {
    max-width: 400px;
    text-align: left;
    margin-top: 16px;
    font-size: 14px;
}

.Crop {
    padding: 8px;
    display: flex;
}

.CropButtonsContainer {
    margin: 8px;
    display: flex;
}

.CropButtons {
    display: inline-block;
    align-self: flex-start;
}

.DropzoneContainer {
    width: 200px;
    height: 200px;
    position: relative;
    margin: 16px 48px 8px 48px;
    cursor: pointer;
    color: grey;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.Dropzone {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    display: inline-block;
    background-color: #EEEEEE;
}

.Transparent {
    opacity: 0.5;
}

.DropzoneCropContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.DropzoneCropButton {
    display: flex;
    align-items: center;
}

.DropzoneSelferContainer {
    position: absolute;
    top: 0;
    background: #f6f9fdea;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: small;
    padding: 8px;
    text-transform: uppercase;
}

.DropzoneSelferContainer b {
    color: #2962ff;
}

.DropzoneTextContainer {
  width: 100%;
  height: 100%;
  margin-top: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  flex-direction: column;
}

.DropzoneText {
    font-size: 1em;
    padding: 8px;
    text-align: center;
}

.DropzoneImage {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.SearchButtons{
    margin: 8px;
}

.LinkDescription {
    color: grey;
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin-top: 8px;
}

.SocialButtons {
    margin-top: 16px;
}

.SocialButtons img {
    height: 36px;
}

.SocialButtons a {
    margin: 4px;
}

.Ad {
    width: 100%;
    margin: 16px 8px 8px 8px;
}

.WarInfo {
    width: 250px;
    height: 230px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding: 8px;
    padding-top: 16px;
    font-size: 18pt;
    text-decoration: none;
    background-size: cover;
    margin-top: 8px;
    border-radius: 8px;
}

.WarInfo:link, .WarInfo:visited {
    color: white;
}

.IhancerAd {
    margin: 6px 8px;
    color: #E0E0E0;
    max-width: 350px;
}

.IhancerAd a {
    padding: 6px 16px;
    line-height: 1.8em;
    background: linear-gradient(90deg, #d0c3a7, #fbefd9);
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    color: #8f6820;
    text-decoration: none;
    border-radius: 4px;
}

.IhancerAd span {
    animation: 6.5s infinite image_blur;
}

@keyframes image_blur {
    0% { -webkit-filter: blur(1.8px);}
    75% { -webkit-filter: blur(0px);}
    100% { -webkit-filter: blur(1.8px);}
}

.PrivacyPolicyLink {
    margin-top: 16px;
}

.PrivacyPolicyLink a {
    color: black;
}

.WNTDAd {
    margin: 8px;
    margin-top: 40px;
    margin-bottom: 24px;
}

.GamezopAd {
    margin: 8px;
    margin-top: 40px;
    margin-bottom: 24px;
    font-weight: bold;
    text-align: center;
    
}

.GamezopAd a {
    color: #9132FF;
    text-decoration: none;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CropIcon {
    font-size: 8;
}